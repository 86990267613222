/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "The Paddock"), "\n", React.createElement(_components.p, null, "The Glebe Paddock was purchased from Lincoln Diocese in 1998 so that it could be preserved for the benefit of the community. It is known locally as ‘the chicken field’. In 2014, a war memorial was installed to commemorate the local fallen in the two world wars. Wreaths are laid at it, each year on Armistice Day."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
